import * as React from 'react';
import { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { subscribe, unsubscribe, publish } from 'javascripts/utils/events';
import AddressPanel from './AddressPanel';
import Input from './Input';
import Select from './Select';
import userService from 'javascripts/services/user-service';

const newAddressFormSchema = yup.object().shape({
  name: yup.string().trim().required('This field is required.'),
  company: yup.string().trim().nullable(),
  street1: yup.string().trim().required('This field is required.'),
  street2: yup.string().trim().nullable(),
  city: yup.string().trim().required('This field is required.'),
  state: yup.string().required('This field is required.'),
  zip: yup.string()
    .required('This field is required.')
    .matches(/^\d+$/, 'ZIP Code must be a number.')
    .length(5, 'ZIP Code must be 5 digits.'),
  phone: yup.string()
    .when('state', {
      is: (state: string) => userService.isPhoneNumberRequired(state),
      then: yup.string().trim().nullable().required('This field is required.'),
      otherwise: yup.string().nullable(),
    }),
  preferred_shipping_carrier_id: yup.number().required('Please select a preferred shipping carrier.')
});

const defaultState = userService.getDefaultState();
const defaultShippingCarrier = userService.getDefaultShippingCarrier(defaultState) || 1;
const defaultValues = {
  state: defaultState,
  preferred_shipping_carrier_id: defaultShippingCarrier
};

const NewAddressForm = (props: { address?: AddressData, onCreateCancelled?: () => void, onEditCancelled?: () => void }) => {
  const editMode = props.address !== undefined;
  const editAddress = props.address;

  const [loading, setLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm<AddressData>({
    defaultValues: editMode ? props.address : defaultValues,
    resolver: yupResolver(newAddressFormSchema)
  });

  const watchState = watch('state');
  const states = userService.getStateOptions();
  const shippingCarriers = userService.getShippingCarrierOptions(watchState);

  const phoneNumberPlaceholder = React.useMemo(() => (
    userService.isPhoneNumberRequired(watchState) ? "" : "Optional, but helps with delivery problems."
  ), [watchState])

  // reset shipping carrier when state changes
  useEffect(() => {
    const shippingCarrier = userService.getDefaultShippingCarrier(watchState);
    if (shippingCarrier) setValue('preferred_shipping_carrier_id', shippingCarrier);
  }, [watchState, shippingCarriers]);

  // handle create/edit submissions
  const onSubmit = async (address: AddressData) => {
    setLoading(true);
    setServerErrors([]);
    const response = editMode ? await userService.editAddress(address) : await userService.createAddress(address, true);
    if (response.result === 'error') {
      setServerErrors(response.errors);
    } else {
      setServerErrors([]);
      publish('CHECKOUT_ADDRESS_SELECTION_REQUESTED', response.address);
      publish('CHECKOUT_UPDATE_USER_DETAILS');
      publish('CLOSE_MODAL', 'address-management');
      if (props.onEditCancelled) props.onEditCancelled();
    }
    setLoading(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header sticky">
        <div className="title">
          { editMode ? `Editing Address for ${editAddress?.name}` : 'New Address' }
        </div>
        <button type="button" className="modal-toggle" data-modal-toggle>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      { editMode ?
        <div className="ui-message edit-message warning">
          <p>You are currently editing address details for <strong>{editAddress?.name}</strong>.</p>
          <p>To cancel editing, please click the <strong>Cancel Edit</strong> button below.</p>
        </div>
      : null }
      <div className="modal-body">
        <div className="ui-form">
          <Input label="Name" id="address-name" name="name" register={register} error={errors.name?.message} placeholder="Agent Red" maxLength={40} />
          <Input label="Company" id="address-company" name="company" register={register} error={errors.company?.message} placeholder="Optional" maxLength={50} />
          <div className="grid-row">
            <div className="col-12 col-xs-6">
              <Input label="Street Address" id="address-street1" name="street1" register={register} error={errors.street1?.message} maxLength={50} />
            </div>
            <div className="col-12 col-xs-6">
              <Input label="Apt/Suite" id="address-street2" name="street2" register={register} error={errors.street2?.message} placeholder="Optional" maxLength={50} />
            </div>
          </div>
          <div className="grid-row">
            <div className="col-12 col-sm-5">
              <Input label="City" id="address-city" name="city" register={register} error={errors.city?.message} minLength={3} maxLength={50} />
            </div>
            <div className="col-12 col-xs-8 col-sm-4">
              <Select options={states} label="State" id="address-state" name="state" register={register} error={errors.state?.message} />
            </div>
            <div className="col-12 col-xs-4 col-sm-3">
              <Input label="ZIP Code" id="address-zip-code" name="zip" register={register} error={errors.zip?.message} placeholder="00000" maxLength={5} />
            </div>
          </div>
          <div className="grid-row">
            <div className="col-12 col-xs-6">
              <Input label="Phone Number" id="address-phone" name="phone" register={register} error={errors.phone?.message} placeholder={phoneNumberPlaceholder} maxLength={25} />
            </div>
            <div className="col-12 col-xs-6">
              <Select options={shippingCarriers} label="Preferred Carrier" id="address-shipping-carrier" name="preferred_shipping_carrier_id" register={register} error={errors.preferred_shipping_carrier_id?.message} />
            </div>
          </div>

          { serverErrors.length > 0 ?
            <div className="ui-message error">
              <p>Please resolve the following errors:</p>
              <ul>
                { serverErrors.map(error => (
                  <li key={error}>
                    <strong>{error}</strong>
                  </li>
                )) }
              </ul>
            </div>
          : null }
        </div>
      </div>
      <div className="modal-footer sticky">
        <div className="grid-row">
          <div className="col-auto">
            { editMode ?
              <button type="button" className="ui-button secondary" onClick={props.onEditCancelled}>Cancel Edit</button>
            : props.onCreateCancelled ?
              <button type="button" className="ui-button secondary" onClick={props.onCreateCancelled}>Cancel</button>
            :
              <button type="button" className="ui-button secondary" data-modal-toggle>Dismiss</button>
            }
          </div>
          <div className="col"></div>
          <div className="col-auto">
            <button type="submit" className={`ui-button ${ loading ? 'loading' : '' }`}>
              { editMode ?
                <>
                  <span className="text h-to-xs">Save Changes</span>
                  <span className="text h-from-sm">Save</span>
                </>
              :
                <>
                  <span className="text h-to-xs">Save and Use Address</span>
                  <span className="text h-from-sm">Save</span>
                </>
              }
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const AddressManagementModal = (props: { showPreferred?: boolean, hideCheckboxes?: boolean }) => {
  const { showPreferred, hideCheckboxes } = props;
  const [savedAddresses, setSavedAddresses] = useState<AddressData[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<AddressData | null>(null);
  const [editAddress, setEditAddress] = useState<AddressData | null>(null);
  const [createMode, setCreateMode] = useState(false);

  const leftColumn = React.useRef<HTMLDivElement>(null);

  const setShippingAddress = useCallback((address: AddressData) => {
    publish('CHECKOUT_ADDRESS_SELECTION_REQUESTED', address);
  }, []);

  const handleOnDelete = useCallback(() => {
    publish('CHECKOUT_UPDATE_USER_DETAILS');
  }, []);

  const handleOnEdit = useCallback((address: AddressData) => {
    setCreateMode(false);
    setEditAddress(address);
  }, []);

  const cancelEdit = useCallback(() => {
    setEditAddress(null);
  }, []);

  // subscribe to events
  useEffect(() => {
    const token1 = subscribe('CHECKOUT_ADDRESSES_UPDATED', (data) => {
      setSavedAddresses([...data]);
      setCreateMode(false);
      cancelEdit();
    });
    const token2 = subscribe('CHECKOUT_ADDRESS_SELECTION_UPDATED', (data) => setSelectedAddress({...data}));
    return () => {
      unsubscribe(token1);
      unsubscribe(token2);
    }
  }, [setSavedAddresses, setSelectedAddress]);

  // handle automatic modal resizing
  useLayoutEffect(() => {
    if (!leftColumn.current) return;

    const modalRoot = leftColumn.current.closest('.ui-modal-root');
    if (!modalRoot) return;

    modalRoot.classList.remove('modal-xs');
    modalRoot.classList.remove('modal-sm');
    modalRoot.classList.remove('modal-md');
    modalRoot.classList.remove('modal-lg');
    modalRoot.classList.remove('modal-xl');

    if (editAddress || createMode) {
      modalRoot.classList.add('modal-xl');
      modalRoot.classList.add('edit-form-active');
    } else {
      if (savedAddresses.length === 0) modalRoot.classList.add('modal-md');
      else modalRoot.classList.add('modal-sm');
      modalRoot.classList.remove('edit-form-active');
    }
  }, [editAddress, createMode, leftColumn.current, savedAddresses]);

  // build list of address panels
  var addresses = [];
  for (const address of savedAddresses) {
    const selected = selectedAddress?.id === address.id;
    addresses.push(
      <AddressPanel key={address.id} address={address} selected={selected} editable showPreferred={showPreferred} hideCheckbox={hideCheckboxes} setShippingAddress={setShippingAddress} onEdit={() => handleOnEdit(address)} onDelete={handleOnDelete} />
    );
  }

  if (addresses.length === 0) {
    return (
      <div className="left-column" ref={leftColumn}>
        <NewAddressForm />
      </div>
    );
  } else {
    return (
      <>
        <div className="left-column" ref={leftColumn}>
          <div className="modal-header sticky">
            <div className="title">
              Manage Addresses
            </div>
            <button className="modal-toggle" data-modal-toggle>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="modal-body">
            <div className="ui-selection-list">
              { addresses }
            </div>
          </div>
          <div className="modal-footer sticky">
            <div className="grid-row">
              <div className="col-auto">
                <button type="button" className="ui-button secondary" data-modal-toggle>
                  <span className="text">Dismiss</span>
                </button>
              </div>
              <div className="col"></div>
              <div className="col-auto">
                { !createMode ?
                  <button type="submit" className="ui-button" disabled={editAddress != null} onClick={() => setCreateMode(!createMode)}>
                    <span className="text h-to-xs">New Address</span>
                    <span className="text h-from-sm">New</span>
                  </button>
                : null }
              </div>
            </div>
          </div>
        </div>
        <div className="right-column">
          { editAddress == null ?
            <div key="new"><NewAddressForm onCreateCancelled={() => setCreateMode(false)} /></div>
          :
            <div key={editAddress.id}><NewAddressForm address={editAddress} onEditCancelled={cancelEdit} /></div>
          }
        </div>
      </>
    );
  }
};

export default AddressManagementModal;