// configure Rails UJS
import * as Rails from '@rails/ujs';
Rails.start();

// configure gsap plugins globally
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);
gsap.defaults({
  duration: 0.5,
  ease: 'power2.out'
});

// configure axios calls to include CSRF token
import axios from 'axios';
const csrfToken = (document.querySelector('meta[name=csrf-token]') as HTMLMetaElement).content;
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

// enable global behaviors
import 'javascripts/components/header-nav';
import 'javascripts/components/footer';
import 'javascripts/components/slideout-cart';
import 'javascripts/components/modal';
import 'javascripts/components/redesign-announcement';

// render react components
var componentRequireContext = require.context('../components', true);
import * as ReactRailsUJS from 'react_ujs';
ReactRailsUJS.useContext(componentRequireContext);

// import vendor scripts
import 'javascripts/vendor/font-awesome';
import 'javascripts/vendor/lazysizes';
import 'javascripts/vendor/tippy';

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://e2d130695d664802a25ebb7ecde1ee77@o1269655.ingest.sentry.io/6459921",
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.5,
  allowUrls: [
    'winespies.com',
    'spieshq.dev'
  ]
});

// @ts-ignore
const userId = window.USER_ID

Sentry.setUser({ id: userId })
