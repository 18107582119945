import * as React from 'react';
import { useContext } from 'react';
import { CheckoutContext } from './CheckoutPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSnowflake, faTruck, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import TempControlRequired from './TempControlRequired';
import TempControlOptional from './TempControlOptional';
import TempControlForbidden from './TempControlForbidden';

const snowflakeTop: string = require('../../assets/images/checkout/temp-control-snowflake-top.svg');
const snowflakeBottom: string = require('../../assets/images/checkout/temp-control-snowflake-bottom.svg');

const CheckoutTempControl = (props: { readonly?: boolean }) => {
  const [state, { setZoneSkipped, setIcePacks }] = useContext(CheckoutContext);
  const { order, preview } = state;

  const address = order.selected_address;
  if (!address) return null;

  const available = address.zone_skipping ? address.zone_skipping !== 'forbidden' : false;
  const mandatory = address.zone_skipping === 'required';

  let zone_skipped = false;
  if (available) zone_skipped = mandatory ? true : (order.zone_skipped || false);

  let ice_packs = order.ice_packs || false;
  if (available && !zone_skipped) ice_packs = false;

  let allow_ice_packs = address.allow_ice_packs;

  // Temporarily override ice packs and temp control to true
  React.useEffect(() => {
    if (allow_ice_packs) {
      setIcePacks(true)
    }
    if (available) {
      setZoneSkipped(true)
    }
  }, [allow_ice_packs, available])

  if (props.readonly) {
    let readonlyHeading = 'No Temperature Control';
    if (zone_skipped) readonlyHeading = 'Shipping Temp. Controlled';
    if (ice_packs) readonlyHeading = 'Ice Packs Included';

    return (
      <div className="ui-panel checkout-temp-control-panel preview">
        <div className="panel-body">
          <h2>
            {readonlyHeading}
            { preview && preview.ice_packs ?
              <>
                &nbsp;<span className="ice-packs-cost">(${preview.ice_packs.str})</span>
              </>
            : null }
          </h2>

          <div className="temp-control-viz">
            <div className="terminal active">
              <FontAwesomeIcon icon={faWarehouse} />
            </div>
            <div className="node active"></div>
            { available ? (
              <>
                <div className={`path large ${ zone_skipped ? 'active' : '' }`}>
                  <div className="path-node">
                    <FontAwesomeIcon icon={faTruck} />
                  </div>
                </div>
                <div className={`node ${ zone_skipped ? 'active' : '' }`}></div>
              </>
            ) : null }
            { allow_ice_packs?  (
            <>
              <div className={`path ${ ice_packs ? 'active' : '' }`}>
                <div className="path-node">
                  <FontAwesomeIcon icon={faSnowflake} />
                </div>
              </div>
              <div className={`node ${ ice_packs ? 'active' : '' }`}></div>
            </>
            ) :
            <>
              <div className="path"/>
              <div className="node"/>
            </>
            }
            <div className={`terminal ${ ice_packs ? 'active' : '' }`}>
              <FontAwesomeIcon icon={faHome} />
            </div>
          </div>
        </div>

        <div className="background-detail">
          <img className="snowflake-top" src={snowflakeTop} />
          <img className="snowflake-bottom" src={snowflakeBottom} />
        </div>
      </div>
    );
  }

  return (
    <div className="ui-panel checkout-temp-control-panel">
      <div className="panel-body">
        <h2>Temperature Control</h2>

        { mandatory ?
          <TempControlRequired
            allow_ice_packs={allow_ice_packs}
            ice_packs={ice_packs}
            zone_skipped={zone_skipped}
            onChange={setIcePacks}
            show_label={!!preview?.ice_packs}
            label_string={preview?.ice_packs?.str || ''}
          />
          : null }

        { available && !mandatory ?
          <TempControlOptional
            allow_ice_packs={allow_ice_packs}
            ice_packs={ice_packs}
            zone_skipped={zone_skipped}
            onChangeIcePacks={setIcePacks}
            onChangeZoneSkipped={setZoneSkipped}
            show_label={!!preview?.ice_packs}
            label_string={preview?.ice_packs?.str || ''}
          />
        : null }

        { !available ?
          <TempControlForbidden
          allow_ice_packs={allow_ice_packs}
          ice_packs={ice_packs}
          onChangeIcePacks={setIcePacks}
          show_label={!!preview?.ice_packs}
          label_string={preview?.ice_packs?.str || ''}
          />
      : null }
      </div>

      <div className="background-detail">
        <img className="snowflake-top" src={snowflakeTop} />
        <img className="snowflake-bottom" src={snowflakeBottom} />
      </div>
    </div>
  );
};

export default CheckoutTempControl;
