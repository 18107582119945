import * as React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { formatCityStateZip, formatStreet } from 'javascripts/utils/address';
import userService from 'javascripts/services/user-service';

export type AddressPanelProps = {
  address: AddressData;
  selected: boolean;
  editable?: boolean;
  showPreferred?: boolean;
  hideCheckbox?: boolean;
  setShippingAddress?: (address: AddressData) => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

const AddressPanel = (props: AddressPanelProps) => {
  const [deleting, setDeleting] = useState(false);
  const { address, selected, editable, showPreferred, hideCheckbox, setShippingAddress, onEdit, onDelete } = props;

  const editAddress = async () => {
    if (onEdit) onEdit();
  }

  const deleteAddress = async () => {
    setDeleting(true);
    const response = await userService.deleteAddress(address);
    if (response.result === 'error') {
      const message = response.errors ? response.errors[0] : 'Failed to delete address.';
      alert(message);
    } else {
      if (onDelete) onDelete();
    }
    setDeleting(false);
  }

  return (
    <label className={`ui-selection-panel address ${ selected ? 'selected' : 'muted' } ${ hideCheckbox ? 'hide-checkbox' : '' }`}>
      <input type="radio" name="payment-method" checked={selected} onChange={() => setShippingAddress && setShippingAddress(address)} className="selection-checkbox" />
      <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
      <div className="checkbox checked"><FontAwesomeIcon icon={faCheckCircle} /></div>
      <div className="title">
        {address.name}
        { showPreferred && address.user_default ? <span className="badge">Default</span> : null }
      </div>
      <div className="content">
        { selected ? (
          <>
            { address.company ? <div className="company">{address.company}</div> : null }
            {formatStreet(address)}<br />
            {formatCityStateZip(address)}<br />
            {address.phone ? <div className="phone">{address.phone}</div> : null }
            <div className="preferred-shipping-carrier">{address.preferred_shipping_carrier.name} preferred</div>
          </>
        ) : (
          <>
            {formatStreet(address)}, {formatCityStateZip(address)}
          </>
        ) }
      </div>

      { editable ?
        <div className="panel-actions">
          <button type="button" className="panel-action" onClick={editAddress}>
            Edit
            <span className="icon">
              <FontAwesomeIcon icon={faEdit} />
            </span>
          </button>
          <button type="button" className={`panel-action ${ deleting ? 'loading' : '' }`} onClick={deleteAddress}>
            Delete
            <span className="icon">
              <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          </button>
        </div>
      : null }
    </label>
  );
}

export default AddressPanel;
